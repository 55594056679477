import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/App.css";
import "./css/normalize.css";
import "./css/webflow.css";
import "./css/edutools.webflow.css";
import NotFound from "./pages/NotFound";
import Cancel from "./pages/Cancel";
import DownloadApp from "./pages/DownloadApp";
import Failure from "./pages/Failure";
import Home from "./pages/Home";
import HyperLinks from "./pages/HyperLinks";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/download-app" element={<DownloadApp />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/123hyperlinks123" element={<HyperLinks />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
