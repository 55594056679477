import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import checkIcon from "../images/Check.svg";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Whether animation should happen only once
    });
  }, []);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const companyLogos = [
    {
      src: "./images/wake-forest.png",
      alt: "Wake Forest",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/usc.png",
      alt: "USC",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/ucsd.png",
      alt: "UCSD",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/stanford.png",
      alt: "Stanford",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/ucla.png",
      alt: "UCLA",
      srcSet: "./images/ucla-p-500.png 500w, ./images/ucla-p-800.png 800w, ./images/ucla.png 1045w",
      sizes: "(max-width: 1439px) 100vw, 3200.3125px",
    },
    {
      src: "./images/berkeley.png",
      alt: "Berkeley",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/nyu.png",
      alt: "NYU",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/purdue.png",
      alt: "Purdue",
      srcSet: "./images/purdue-p-500.png 500w, ./images/purdue-p-800.png 800w, ./images/purdue.png 918w",
      sizes: "(max-width: 479px) 64.63542175292969px, (max-width: 767px) 13vw, 98.48958587646484px",
    },
  ];

  // Testimonials Data Array
  const testimonials = [
    {
      id: 1,
      userImage: {
        src: "./images/LoganGubser.jpg",
        alt: "Logan Gubser",
        srcSet: "./images/LoganGubser-p-500.jpg 500w, ./images/LoganGubser.jpg 621w",
        sizes: "(max-width: 479px) 25vw, 60px",
      },
      userName: "Logan Gupser",
      userPosition: "UW-Madison",
      companyLogo: {
        src: "./images/Wisconsin_Badgers_logo.svg.png",
        alt: "Wisconsin Badgers",
        srcSet: "./images/Wisconsin_Badgers_logo.svg-p-500.png 500w, ./images/Wisconsin_Badgers_logo.svg.png 640w",
        sizes: "45px",
        width: 45,
      },
      testimonialText: "If you don't have Edutools, what are you doing?",
    },
    {
      id: 2,
      userImage: {
        src: "./images/MiaChen.png",
        alt: "Mia Chen",
        srcSet: "./images/MiaChen-p-500.png 500w, ./images/MiaChen-p-800.png 800w, ./images/MiaChen.png 1080w",
        sizes: "60px",
      },
      userName: "Mia Chen",
      userPosition: "MIT",
      companyLogo: {
        src: "./images/MIT_logo.svg.png",
        alt: "MIT Logo",
        srcSet: "./images/MIT_logo.svg-p-500.png 500w, ./images/MIT_logo.svg-p-800.png 800w, ./images/MIT_logo.svg-p-1080.png 1080w, ./images/MIT_logo.svg-p-1600.png 1600w, ./images/MIT_logo.svg-p-2000.png 2000w, ./images/MIT_logo.svg.png 2560w",
        sizes: "60px",
        width: 60,
      },
      testimonialText: "THE all-in-one solution that every student needs.",
    },
    {
      id: 3,
      userImage: {
        src: "./images/JasmineH.jpg",
        alt: "Jasmine H.",
        srcSet: "./images/JasmineH-p-500.jpg 500w, ./images/JasmineH-p-800.jpg 800w, ./images/JasmineH.jpg 1080w",
        sizes: "60px",
      },
      userName: "Jasmine H.",
      userPosition: "Stevenson High",
      companyLogo: {
        src: "./images/SHS_S__005.webp",
        alt: "Stevenson High School Logo",
        srcSet: "./images/SHS_S__005-p-500.webp 500w, ./images/SHS_S__005-p-800.webp 800w, ./images/SHS_S__005.webp 860w",
        sizes: "50px",
        width: 50,
      },
      testimonialText: "This is what you need to succeed as a student.",
    },
    {
      id: 4,
      userImage: {
        src: "./images/AidenS.png",
        alt: "Aiden Sheena",
        srcSet: "./images/AidenS-p-500.png 500w, ./images/AidenS-p-800.png 800w, ./images/AidenS.png 898w",
        sizes: "60px",
      },
      userName: "Aiden Sheena",
      userPosition: "San Diego State University",
      companyLogo: {
        src: "./images/800x600-athletics-logo.png",
        alt: "Athletics Logo",
        srcSet: "./images/800x600-athletics-logo-p-500.png 500w, ./images/800x600-athletics-logo.png 800w",
        sizes: "45px",
        width: 45,
      },
      testimonialText: "Edutools has saved me so much time, allowing me to focus on other important areas of my life.",
    },
  ];

  const pricingPlans = [
    {
      id: 1,
      plan: "Free",
      billingType: "Free forever",
      price: "$0",
      button: {
        href: "#",
        text: "Current Plan",
        className: "button third w-button",
        style: {
          pointerEvents: "none",
          opacity: 0.5,
        },
      },
      features: ["Basic study resources", "Access to our network of like-minded students", "Additional features to be announced"],
      isCurrent: true,
    },
    {
      id: 2,
      plan: "Monthly",
      billingType: "Billed monthly.",
      price: "$19.99",
      button: {
        href: "./login",
        text: "Get started",
        className: "button secondary w-button",
        style: {},
      },
      features: ["Access to all basic features", "Access to premium accounts", "Access to our in-browser solution", "High speed desktop app", "24/7 chat support"],
      isCurrent: false,
    },
    {
      id: 3,
      plan: "Semesterly",
      billingType: "Billed per semester.",
      price: "$49.99",
      button: {
        href: "./login",
        text: "Get started",
        className: "button secondary w-button",
        style: {},
      },
      features: ["3 months FREE", "Access to all monthly features", "Special perks and features"],
      isCurrent: false,
    },
  ];

  const faqData = [
    {
      question: "Why choose EduTools?",
      answer: "EduTools is the ultimate all-in-one subscription for students. It saves you time, money, and effort while providing everything you need to excel academically. From premium study tools to workflow optimization, EduTools ensures you never have to buy another subscription again.",
      icon: "./images/Purple-Plus.svg",
    },
    {
      question: "How can I create an account?",
      answer: "Creating an account is quick and easy! Simply sign in with your Google account to get started and explore all the tools EduTools has to offer.",
      icon: "./images/Purple-Plus.svg",
    },
    {
      question: "Who is Nova?",
      answer: "Nova is your AI-powered study assistant, designed to enhance your learning experience. Nova helps you auto-fill text, create quizzes from documents, and simplify complex content into digestible formats. Meet your new ultimate study companion—Nova!",
      icon: "./images/Purple-Plus.svg",
    },
    {
      question: "Does EduTools consider suggestions for new features?",
      answer: "Absolutely! EduTools values input from our community and is dedicated to improving the platform. Share your suggestions, and we’ll strive to bring your ideas to life.",
      icon: "./images/Purple-Plus.svg",
    },
    {
      question: "How can I contact EduTools?",
      answer: 'Our support team is available 24/7 to assist with your questions or concerns. Reach out to us through the in-app support chat or email us at <a href="mailto:contact@edutools.app">contact@edutools.app</a>.',
      icon: "./images/Purple-Plus.svg",
    },
  ];

  const [user] = useAuthState(auth);

  return (
    <>
      <Navbar />
      <div id="hero-section" data-w-id="92be72c0-3a98-a5eb-437e-48540808feff" className="section overflow-hidden">
        <div className="container">
          <div className="hero-section-holder">
            <div className="ease-hero-section-grid">
              <div className="ease-hero-section-left-side">
                <div
                  className="animate-on-load-01"
                  sx={{
                    WebkitTransform: "translate3d(0, 30px, 0) scale3d(1, 1, 1)",
                    MozTransform: "translate3d(0, 30px, 0) scale3d(1, 1, 1)",
                    msTransform: "translate3d(0, 30px, 0) scale3d(1, 1, 1)",
                    transform: "translate3d(0, 30px, 0) scale3d(1, 1, 1)",
                    opacity: 0,
                  }}
                >
                  <di data-aos="fade-left" v className="badge-holder">
                    <a className="badge w-inline-block">
                      <div>Beta v0.13</div>
                      <img loading="lazy" src="https://uploads-ssl.webflow.com/65e99a35bcb8be4dd79e0121/65fdd935cc8385f315240ff6_Arrow%20Right.svg" alt="" className="badge-icon" />
                    </a>
                  </di>
                </div>
                <div
                  className="animate-on-load-02"
                  sx={{
                    transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                >
                  <div data-aos="fade-right" className="home-hero-text-holder">
                    <h1 className="gradient-title">
                      Save. Learn.
                      <br />
                      <strong className="bold-text">Succeed.</strong>
                    </h1>
                  </div>
                </div>
                <div
                  data-aos="fade-right"
                  className="animate-on-load-03"
                  sx={{
                    opacity: 0,
                    WebkitTransform: "translate3d(0, 30px, 0)",
                    MozTransform: "translate3d(0, 30px, 0)",
                    msTransform: "translate3d(0, 30px, 0)",
                    transform: "translate3d(0, 30px, 0)",
                  }}
                >
                  <div className="hero-paragraph-holder">
                    <p>Currently in beta. All features are subject to change. This platform is intended for pre-production use.</p>
                  </div>
                </div>
                <div
                  data-aos="fade-right"
                  className="animate-on-load-04"
                  sx={{
                    WebkitTransform: "translate3d(0, 30px, 0)",
                    MozTransform: "translate3d(0, 30px, 0)",
                    msTransform: "translate3d(0, 30px, 0)",
                    transform: "translate3d(0, 30px, 0)",
                    opacity: 0,
                  }}
                >
                  <div className="home-hero-button-holder">
                    <Link href="#pricing-section" className="button secondary w-inline-block">
                      <div>Read More</div>
                      <img loading="lazy" src="https://uploads-ssl.webflow.com/65e99a35bcb8be4dd79e0121/65fd93a44816b698190da010_Arrow%20Right.svg" alt="" className="button-arrow-icon" />
                    </Link>
                    <Link to="/download-app" className="button w-inline-block">
                      <div>Download</div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-left"
                className="ease-hero-dashborad-cutout"
                sx={{
                  opacity: 0,
                  WebkitTransform: "translate3d(280px, 0, 0)",
                  MozTransform: "translate3d(280px, 0, 0)",
                  msTransform: "translate3d(280px, 0, 0)",
                  transform: "translate3d(280px, 0, 0)",
                }}
              >
                <img loading="lazy" src="./images/dashboard.png" alt="" className="hero-dashboard-image-2" />
              </div>
            </div>
          </div>
        </div>
        {/* Trusted By Section */}
        <div id="trusted-by-id" className="trusted-by">
          <div className="center-text">
            <div data-aos="fade-up" className="fade-in-on-scroll">
              <p>
                Trusted by Students Just Like <strong>You</strong>
              </p>
            </div>
          </div>
          <div data-aos="fade-up" className="fade-in-on-scroll">
            <div className="company-logo-holder">
              <div className="scrolling-logos">
                {/* Duplicate logos for smooth infinite scrolling */}
                {[...Array(20)].map((_, index) => (
                  <div className="company-logo-container" key={index}>
                    {companyLogos.map((logo, logoIndex) => (
                      <div className="company-logo-wrapper" key={logoIndex}>
                        <img src={logo.src} loading="lazy" alt={logo.alt} className="company-logo" {...(logo.srcSet && { srcSet: logo.srcSet })} {...(logo.sizes && { sizes: logo.sizes })} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {/* Gradient overlays */}
              <div className="gradient-for-logos"></div>
              <div className="gradient-for-logos right"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Features Section */}
      <div id="features-section" class="section">
        <div class="container">
          <div class="ease-bento-grid-holder-2">
            <div className="w-layout-grid ease-bento-grid-02">
              <div id="w-node-_9c3b245d-ec6e-4c1b-fcac-ff45f7234861-c586e0f5" className="bento-grid-container">
                <div className="ease-bento-grid-04">
                  <div data-aos="fade-up" className="ease-bento-grid-text-wrapper">
                    <div className="ease-bento-grid-heading-01 ease-title">Intuitive Dashboard</div>
                  </div>
                  <div className="bento-grid-image-holder">
                    <img
                      className="bento-grid-image"
                      src="./images/Bento-Image-07_1Bento-Image-07.webp"
                      style={{ opacity: 0 }}
                      sizes="(max-width: 479px) 82vw, (max-width: 767px) 87vw, (max-width: 991px) 50vw, (max-width: 1439px) 56vw, 703.1979370117188px"
                      data-w-id="9c3b245d-ec6e-4c1b-fcac-ff45f7234867"
                      loading="lazy"
                      srcSet="
                ./images/Bento-Image-07_1Bento-Image-07.webp 500w,
                ./images/Bento-Image-07_1-p-800.png 800w,
                ./images/Bento-Image-07_1-p-1080.png 1080w,
                ./images/Bento-Image-07_1-p-1600.png 1600w,
                ./images/Bento-Image-07_1-p-2000.png 2000w,
                ./images/Bento-Image-07_1Bento-Image-07.webp 3004w
              "
                    />
                  </div>
                </div>
              </div>

              <div className="bento-grid-container ease-outside">
                <div className="bento-grid-image-holder ease-outside">
                  <img
                    className="bento-grid-image"
                    src="./images/Blaca-Card.png"
                    alt="Blaca Card"
                    style={{ opacity: 0 }}
                    sizes="(max-width: 479px) 88vw, (max-width: 767px) 93vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, 1142.666748046875px"
                    data-w-id="9c3b245d-ec6e-4c1b-fcac-ff45f723486a"
                    loading="lazy"
                    srcSet="
              ./images/Blaca-Card-p-500.png 500w,
              ./images/Blaca-Card-p-800.png 800w,
              ./images/Blaca-Card-p-1080.png 1080w,
              ./images/Blaca-Card.png 1138w
            "
                  />
                </div>
                <div className="ease-bento-grid-content">
                  <div className="ease-bento-grid-heading-03">Personal AI-Assistant</div>
                  <p className="paragraph-s">Summarize articles, rewrite notes, create quizzes and more with your personal AI-assistant, Nova!</p>
                </div>
              </div>

              <div className="bento-grid-container ease-outside">
                <div className="bento-grid-image-holder ease-outside">
                  <img
                    className="bento-grid-image"
                    src="./images/integrations.png"
                    style={{
                      opacity: 1,
                      transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    sizes="(max-width: 479px) 88vw, (max-width: 767px) 93vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, 1142.666748046875px"
                    data-w-id="9c3b245d-ec6e-4c1b-fcac-ff45f7234872"
                    loading="lazy"
                    srcSet="
              ./images/integrations.png 500w,
              ./images/integrations.png 800w,
              ./images/integrations.png 1080w,
              ./images/integrations.png 1600w,
              ./images/integrations.png 1710w
            "
                  />
                </div>

                <div className="ease-bento-grid-content">
                  <div className="ease-bento-grid-heading-03">Seamlessly Integrated Tools</div>
                  <p className="paragraph-s">All your favorite academically related services, all in one place, just one click away!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reviews Section */}
      <div id="review-section" className="section overflow-hidden">
        <div className="container">
          <div className="section-paddings">
            <div className="section-center-text">
              <div className="section-title">
                <div data-aos="fade-up" className="fade-in-on-scroll">
                  <h2 className="title medium">
                    Loved by Students Like <strong>You</strong>
                  </h2>
                </div>
              </div>
              <div className="section-paragraph">
                <div data-aos="fade-up" className="fade-in-on-scroll">
                  <p>Hear what students around the world have to say about Edutools!</p>
                </div>
              </div>
            </div>

            {/* Testimonials Grid */}
            <div className="testimonials-grid">
              {/* Map through testimonials to create grid items */}
              {testimonials.map((testimonial) => (
                <div className="testimonial-item testimonal-slide" key={testimonial.id}>
                  <div className="testimonal-info-wrapper">
                    <div className="testimonal-info">
                      <img src={testimonial.userImage.src} loading="lazy" alt={testimonial.userImage.alt} className="user-image" srcSet={testimonial.userImage.srcSet} sizes={testimonial.userImage.sizes} />
                      <div className="testimonial-info-text">
                        <div className="user-name">{testimonial.userName}</div>
                        <div className="user-job-position">{testimonial.userPosition}</div>
                      </div>
                    </div>
                    <img src={testimonial.companyLogo.src} loading="lazy" width={testimonial.companyLogo.width} alt={testimonial.companyLogo.alt} srcSet={testimonial.companyLogo.srcSet} sizes={testimonial.companyLogo.sizes} className="testimonal-logo" />
                  </div>
                  <div className="testimonial-text">{`"${testimonial.testimonialText}"`}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Cross-Platform Support Section */}
      <div className="section">
        <div className="container">
          <div className="section-center-text">
            <div className="section-title xl">
              <div data-aos="fade-up" className="fade-in-on-scroll">
                <h2 className="gradient-title">Cross-Platform Support</h2>
              </div>
            </div>
            <div className="section-paragraph">
              <div data-aos="fade-up" className="fade-in-on-scroll">
                <p>Seamlessly access your tools and workspaces on any device.</p>
              </div>
            </div>
          </div>
          <div className="w-layout-grid ease-features-grid" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", textAlign: "center" }}>
            <div className="ease-feature-item ease-center-text" sx={{ opacity: 0 }} data-w-id="b958814c-5633-fe74-226a-1cd8ee34b51b">
              <img loading="lazy" src="./images/apple.png" alt="Apple Icon" className="feature-icon" />
              <div className="feature-heading-small">Mac</div>
            </div>
            <div className="ease-feature-item ease-center-text" sx={{ opacity: 0 }} data-w-id="b958814c-5633-fe74-226a-1cd8ee34b521">
              <img loading="lazy" src="./images/window.png" alt="Windows Icon" className="feature-icon" />
              <div className="feature-heading-small">Windows</div>
            </div>
            <div className="ease-feature-item ease-center-text" sx={{ opacity: 0 }} data-w-id="b958814c-5633-fe74-226a-1cd8ee34b527">
              <img loading="lazy" src="./images/chrome.png" alt="Chrome Icon" className="feature-icon" />
              <div className="feature-heading-small">Web</div>
            </div>
          </div>
        </div>
      </div>
      {/* Call to Action Section */}
      <div data-aos="fade-up" className="section">
        <div className="container">
          <div className="cta-holder-06">
            <div className="cta-left-right-grid">
              <div data-aos="fade-up" className="fade-in-on-scroll">
                <div className="cta-heading-holder ease-larger">
                  <h3 className="second-title">
                    Become an academic weapon.
                    <br />
                    Start using our app today.
                  </h3>
                </div>
              </div>
              <div data-aos="fade-up" className="fade-in-on-scroll">
                <div className="cta-button-holder">
                  <a to="/download-app"className="button secondary w-inline-block">
                    <div>Explore</div>
                    <img loading="lazy" src="./images/Arrow-Right.svg" alt="Arrow Right" className="button-arrow-icon" />
                  </a>
                  <Link to="/download-app" className="button w-inline-block">
                    <div>Download</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing Section */}
      <div id="pricing-section" className="section">
        <div className="container">
          <div className="section-paddings">
            <div className="section-center-text">
              <div className="section-title xl">
                <div
                  data-aos="fade-up"
                  className="fade-in-on-scroll"
                  style={{
                    transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                    opacity: 1,
                  }}
                >
                  <h2 className="gradient-title">Pricing Plans</h2>
                </div>
              </div>
              <div className="section-paragraph">
                <div
                  data-aos="fade-up"
                  className="fade-in-on-scroll"
                  style={{
                    transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                    opacity: 1,
                  }}
                >
                  <p className="paragraph-2">Choose the pricing plan that suits you best.</p>
                </div>
              </div>
            </div>
            <div className="pricing-wrapper">
              <div className="pricing-list-wrapper w-dyn-list">
                <div className="pricing-grid w-dyn-items">
                  {pricingPlans.map((plan, index) => (
                    <div
                      role="listitem"
                      className={`pricing-list-item w-dyn-item ${plan.plan === "Monthly" ? "middle" : ""}`}
                      key={plan.id}
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                        opacity: 1,
                      }}
                    >
                      <div className={`pricing ${plan.plan === "Monthly" ? "middle" : ""}`}>
                        <div className={`pricing-header ${plan.plan === "Monthly" ? "middle" : ""}`}>
                          <div className="price-content">
                            <div className="price-plan">{plan.plan}</div>
                            <div className="price-type">{plan.billingType}</div>
                          </div>
                          <div className="price">{plan.price}</div>
                        </div>
                        <div className="pricing-button-holder">
                          <Link to={plan.button.href} className={plan.button.className} style={plan.button.style}>
                            {plan.button.text}
                          </Link>
                        </div>
                        <div className="list-holder">
                          <ul className="w-list-unstyled">
                            {plan.features.map((feature, index) => (
                              <li
                                className="list-item"
                                key={index}
                                style={{
                                  transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                  transformStyle: "preserve-3d",
                                  opacity: 1,
                                }}
                              >
                                <div className={`check-icon-holder ${plan.plan === "Monthly" ? "" : "outlined"}`}>
                                  <img src={checkIcon} loading="lazy" alt="Check Icon" className="check-icon" />
                                </div>
                                <div className="check-item-text">{feature}</div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FAQ Section */}
      <div className="section" id="faq-section">
        <div className="container">
          <div className="section-paddings">
            <div className="section-center-text">
              <div className="section-title xl">
                <div data-aos="fade-up" className="fade-in-on-scroll">
                  <h2 className="title medium">Frequently asked questions</h2>
                </div>
              </div>
              <div className="section-paragraph">
                <div data-aos="fade-up" className="fade-in-on-scroll">
                  <p>Everything you need to know</p>
                </div>
              </div>
            </div>
            <div className="questions-holder">
              {faqData.map((faq, index) => (
                <div className={`questions-container ${openIndex === index ? "open" : ""}`} key={index}>
                  <div className="question" onClick={() => toggleFAQ(index)}>
                    <div className="question-text">{faq.question}</div>
                    <img src={faq.icon} loading="lazy" alt="Expand Icon" className={`question-icon ${openIndex === index ? "rotate" : ""}`} />
                  </div>
                  <div
                    className="answer-holder"
                    style={{
                      maxHeight: openIndex === index ? "300px" : "0",
                      overflow: "hidden",
                    }}
                  >
                    <div className="answer-text" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Footer Section */}
      <div className="section">
        <div className="container">
          <div className="footer-wrapper">
            <a href="/" className="footer-brand w-inline-block">
              <div className="w-layout-blockcontainer container-2 w-container">
                <img src="./images/logo_1.png" loading="lazy" width="30" height="30" alt="Edutools Logo" srcSet="./images/logo_1-p-500.png 500w, ./images/logo_1.png 800w" sizes="30px" className="brand-image" />
                <h5 className="heading">Edutools</h5>
              </div>
              <div className="footer-heading">Become an Academic Weapon with Edutools</div>
            </a>
          </div>
          <div className="footer-divider">
            <div className="footer-copyright-holder">
              <div className="footer-copyright-center">
                POWERED by Edutools CO. © 2024 | Contact: <a href="mailto:contact@edutools.app">contact@edutools.app</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Include the rest of your sections similarly, converting HTML to JSX */}
    </>
  );
};

export default Home;
