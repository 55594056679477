import React from 'react';
import "aos/dist/aos.css";

function HyperLinks() {
    const services = [
        { id: 1, name: "ChatGPT", logo: "https://edutools-app.pages.dev/assets/chatgpt.png", link: "https://chatgpt.com/", description: "AI-powered writing and assistance tool." },
        { id: 2, name: "Grammarly", logo: "https://edutools-app.pages.dev/assets/grammarly.png", link: "https://www.grammarly.com/", description: "Grammar and spelling checker." },
        { id: 3, name: "Quizlet", logo: "https://edutools-app.pages.dev/assets/quizlet.png", link: "https://quizlet.com", description: "Flashcards and study tools." },
        { id: 4, name: "Chegg", logo: "https://edutools-app.pages.dev/assets/chegg.png", link: "https://www.chegg.com/", description: "Homework help and solutions." },
        { id: 5, name: "Canva", logo: "https://edutools-app.pages.dev/assets/canva.png", link: "https://www.canva.com/", description: "Graphic design made simple." },
        { id: 6, name: "SparkNotes", logo: "https://edutools-app.pages.dev/assets/sparknotes.png", link: "https://www.sparknotes.com/", description: "Literature summaries and guides." },
        { id: 7, name: "Wolfram Alpha", logo: "https://edutools-app.pages.dev/assets/wolframalpha.png", link: "https://www.wolframalpha.com/", description: "Computational knowledge engine." },
        { id: 8, name: "GPTZero", logo: "https://edutools-app.pages.dev/assets/gptzero.png", link: "https://app.gptzero.me/", description: "Detects AI-generated content." }
    ];

    return (
        <div style={{color: 'white', margin: 0, overflowY: "hidden"}}>
            <h1 className="gradient-title" style={{ textAlign: 'center', marginTop: '50px' }}>Edutools Launchpad</h1>
            <p style={{ textAlign: 'center', marginTop: '10px', fontSize: '17px', color: '#cccccc' }}>
                Click on an app to open it and use it in a new tab!
            </p>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px', margin: '50px auto', maxWidth: '800px' }}>
                {services.map(service => (
                    <div
                        key={service.id}
                        style={{
                            backgroundColor: '#202020',
                            color: 'white',
                            border: '1px solid #333333',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '150px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                            textAlign: 'center',
                            padding: '10px',
                            position: 'relative',
                            overflow: 'hidden'
                        }}
                        onClick={() => window.open(service.link, '_blank')}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#333333'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#202020'}
                    >
                        <img src={service.logo} alt={`${service.name} Logo`} style={{ position: 'relative', zIndex: 2, width: '40px', height: '40px', marginBottom: '10px' }} />
                        <img src={service.logo} alt={`${service.name} Logo`} style={{ position: 'absolute', top: '50%', left: 'calc(100% - 20px)', transform: 'translate(-50%, -50%) scale(1.4)', width: '100%', height: '100%', zIndex: 1, opacity: 0.1, filter: 'blur(5px)' }} />
                        <span style={{ position: 'relative', zIndex: 3, fontWeight: 500, fontSize: '1.2em' }}>{service.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HyperLinks;
